import openSocket from "socket.io-client";
import { isObject } from "lodash";

export function socketConnection(params) {
  let userId = null;
  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
  }

  const backendUrl = process.env.REACT_APP_BACKEND_URL || "";
  const urlParts = backendUrl.split("/");
  const path = urlParts.length > 3 ? `/${urlParts.slice(3).join("/")}/socket.io` : "/socket.io";

  return openSocket(process.env.REACT_APP_BACKEND_URL, {
    transports: ["websocket", "polling", "flashsocket"],
    pingTimeout: 18000,
    pingInterval: 18000,
    path,
    query: isObject(params) ? { ...params} : { userId: params.userId },
  });
}
